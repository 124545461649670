<template>
  <div class="PageBox">
    <div class="ScrollBox">
      <div class="header_bg">
        <div style="font-size: 16px;padding: 5px">
          <van-row>
            <van-col span="12">
              <span style="padding: 10px;font-weight: bold;color: #fff">{{$t('user.default[0]')}}</span>
            </van-col>
            <van-col span="12" style="text-align: right;">
              <van-icon name="./static/icon/setting.png" size="25" @click="onClickRight(0)"/>
            </van-col>
          </van-row>
        </div>
        <div>
          <div class="Mine_box">
            <div class="MyHeader">
              <img class="head" :src="`./static/head/${UserInfo.header}`" />
            </div>
            <div style="margin-top: 10px;margin-left: 10px;font-size: 21px">
              <div style="display: flex">
              {{ UserInfo.username.substring(0,15) }}
                <div class="vipcls">
              V{{ UserInfo.vip_level }}
                </div>
              </div>
              <div style="margin-left: -20px;margin-top: 5px">
                <van-button size="min" color="#0076FA" to="/busup" style="width: 100px;height: 28px;margin-left: 20px">
            <span style="font-size: 13px">
            {{ $t("wallet.default[13]") }}</span></van-button>
              </div>
            </div>

          </div>

          <!-- <div class="user_vip">LV<i>{{UserInfo.vip_level}}</i></div> -->
          <div class="MyHeaderStatus" v-show="agent_type==1">
            {{ $t("user.default[2]") }}:{{ UserInfo.idcode }}
          </div>
        </div>
        <div class="tool00">
          <div style="width: 95%;font-size: 18px">
            <div style="display: flex;margin-bottom: 15px">
              <div style="width: 500px">{{ $t("wallet.default[9]") }}(USDT)  <span style="font-weight: bold"> {{ $Util.formatNum(Number(UserInfo.balance).toFixed(2)) }} </span></div>
              <div style="text-align: right">
                <van-button size="min" color="#0076FA" to="/user/recharge" style="width: 100px;height: 28px">
             <span style="font-size: 13px">
            {{ $t("wallet.default[1]") }}</span></van-button>
              </div>
            </div>
            <div style="display: flex">
              <div style="width: 500px">{{ $t("wallet.default[9]") }}({{InitData.usdtinfo.sign}})  <span style="font-weight: bold"> {{ $Util.formatNum(Number(UserInfo.balance2).toFixed(2)) }} </span></div>
              <div>
                <van-button size="min" color="#0076FA" @click="gotoWithdraw" style="width: 100px;height: 28px">
             <span style="font-size: 13px">
            {{ $t("withdraw2[0]") }}</span></van-button>
              </div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <div style="width: 500px">
                <span>{{ $t("my2[2]") }}({{InitData.usdtinfo.sign}})</span>
                <span style="font-weight: bold"> {{ $Util.formatNum(Number(finamount).toFixed(3)) }} </span></div>
              <van-button size="min" color="#0076FA" to="/user/finlist"  style="width: 100px;height: 28px">
            <span style="font-size: 13px;">
            {{ $t("my2[3]") }}</span></van-button>
            </div>
          </div>
        </div>
        <div class="tool01">
          <van-grid
                  class="Menu top2"
                  direction="vertical"
                  :column-num="3"
                  :border="false"
          >
            <van-grid-item>
              <van-cell
                      center
                      :border="false"
                      :title="`${(Number(UserInfo.total_recharge)).toFixed(2)}`"
                      :label="`${$t('wallet.default[10]')}`"

              />
            </van-grid-item>
            <van-grid-item>
              <van-cell
                      center
                      :border="false"
                      :title="`${(Number(UserInfo.sell_numbers)).toFixed(2)}`"
                      :label="sellDesc"
              />
            </van-grid-item>
            <van-grid-item>
              <van-cell
                      center
                      :border="false"
                      :title="`${(Number(UserInfo.total_commission)).toFixed(2)}`"
                      :label="commDesc"
              />
            </van-grid-item>
          </van-grid>
        </div>
        <!-- 最后一个框 -->
        <div class="tool02" style="height:400px">
          <div class="tool03">
          <van-cell
                  size="large"
                  :title="$t('user.menu[0]')"
                  to="/user/wallet"
                  center
                  is-link
          >
            <template #icon>
              <van-icon name="./static/icon/wallet.png" size="20"/>
            </template>
          </van-cell>
          <van-cell
                  size="large"
                  :title="$t('user.menu[1]')"
                  to="/user/teamReport"
                  center
                  is-link
          >
            <template #icon>
              <van-icon name="./static/icon/team.png" size="20"/>
            </template>
          </van-cell>
          <van-cell
                  size="large"
                  :icon="`./static/icon/bind.png`"
                  :title="$t('user.menu[2]')"
                  to="/user/bindAccount"
                  center
                  is-link
          >
            <template #icon>
              <van-icon name="./static/icon/bind.png" size="20"/>
            </template>
          </van-cell>
          <van-cell
                  size="large"
                  :title="$t('user.menu[3]')"
                  to="/user/fundRecord"
                  center
                  is-link
          >
            <template #icon>
              <van-icon name="./static/icon/account.png" size="20"/>
            </template>
          </van-cell>
          <van-cell
                  size="large"
                  :title="$t('user.menu[6]')"
                  @click="show_interest=true"
                  center
                  is-link
          >
            <template #icon>
              <van-icon name="./static/icon/fund.png" size="20"/>
            </template>
          </van-cell>
          </div>
          <div>
            <p class="btn" @click="$Model.Logout()">{{ $t('user.menu[5]') }}</p>
          </div>
        </div>
        <div style="height: 40px"></div>
      </div>
    </div>
    <Footer v-if="$route.name == 'user'" />
    <van-popup  v-model="showMsg" style="background: transparent; width: 80%; text-align: center" @closed="$parent.isNotice = true">
      <div class="popupBg">
        <dl class="ScrollBox NoticePopup" style="height:350px">
          <dt class="popup_title">
            <div>
              <span>{{
                msg.title
              }}</span>
            </div>
          </dt>
          <dd v-html="msg.content"></dd>
        </dl>
      </div>
      <a class="close" href="javascript:;" @click="showMsg = false">
        <img src="../../../public/static/images/pupup_false.png" />
      </a>
    </van-popup>
    <van-dialog v-model="show_interest"  :title="$t('user.menu[6]')" @confirm="saveInterest"
                :cancel-button-text="$t('common[3]')" :confirm-button-text="$t('common[2]')"
                :show-confirm-button="true"  :show-cancel-button="true">
      <div style="margin:0 auto;text-align:center;margin-top: 30px;margin-bottom: 40px">
        <van-button :color="interType==index?'#0076FA':'#ccc'" @click="interType=index"
                    style="margin: 10px;width: 150px" v-for="(item,index) in interests" :key="index">
          <span :style="interType==index?'color:#FFF':'color:#000'"> {{item.days}} {{$t('my2[0]')}},{{item.rate}}% {{$t('my2[1]')}}</span>
        </van-button>
      </div>
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="interData.amount"
                :placeholder="$t('recharge.label[3]')" style="margin-left: 20px"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import config from "@/config";

  const AppDown = config.AppDown;
  export default {
    name: "User",
    components: {},
    props: [],
    data() {
      return {
        statisticalData: {},
        uid: "", //用户ID
        InferiorList: "",
        sellDesc:'',
        agent_type:0,
        interData:{amount:0,rate:0},
        show_interest:false,
        commDesc:'',
        interType:0,
        interests:[
          {days:7,rate:5},
          {days:15,rate:6.5},
          {days:30,rate:8},
          {days:90,rate:10},
        ],
        finitems:[],
        finamount:0,
        userInfo:{},
        showMsg:false,
        msg:{"title":'',"content":''},
      };
    },
    computed: {},
    watch: {},
    created() {
      this.sellDesc = this.$t('wallet.default[11]')+"("+this.InitData.usdtinfo.sign+")";
      this.commDesc = this.$t('wallet.default[12]')+"("+this.InitData.usdtinfo.sign+")";

      var token = localStorage.getItem("Token");

      this.$Model.GetMsg(data=>{
        if (data.code == 1) {
          this.msg = data['data'];
          if (this.msg['title'].length>0&&this.msg['content'].length>0){
            this.showMsg = true;
          }
        }
      });
      this.loadUserinfo();
      this.$Model.GetStatisticsInfo((data) => {
        if (data.code == 1) {
          this.statisticalData = data.info;
        }
      });
      this.loadFindata();
    },
    mounted() {},
    activated() {},
    destroyed() {},
    methods: {
      onClickRight(index){
        if (index==1){
          this.$router.push("/user/msglist");
        }else{
          this.$router.push("/user/info");
        }
      },
      loadUserinfo(){
        this.$Model.GetUserInfo((data) => {
          if (data.code == 1) {
            this.uid = data.info.uid;
            this.UserInfo = data.info;
          }
        });
      },
      loadFindata(){
        this.$Model.GetFinData({},(data) => {
          if (data.code == 1) {
            var fins = data.info;
            var totalfin = 0;
            for (var i=0;i<fins.length;i++){
              totalfin += parseInt(fins[i]['amount'])
            }
            this.finamount = totalfin;
            this.finitems = fins;
          }
        });
      },
      gotoWithdraw(){
        let info = this.userInfo;
        if (info.withdraw_body&&info.withdraw_body.length>0){
          this.msg['title'] = info.withdraw_title;
          this.msg['content'] = info.withdraw_body;
          this.showMsg = true;
          return;
        }
        let sold_count = this.userInfo['sold_count'];
        // let withdraw_order_count = this.userInfo['withdraw_order_count'];
        // let count = withdraw_order_count-sold_count;
        // if (count>0){
        //   let msg = this.$t('taskOrder2[4]')+count
        //   this.$Dialog.Toast(msg);
        //   return;
        // }

        this.$router.push('/user/withdraw');
      },
      saveInterest(){
        var amount = parseInt(this.interData.amount);
        var balance = parseInt(this.UserInfo.balance2);
        if (amount<=0){
          this.$toast(this.$t('recharge2[0]'));
          return;
        }
        if (amount>balance){
          this.$toast(this.$t('hall.kbip[1]'));
          return;
        }

        var fin = this.interests[this.interType];
        this.interData['amount'] = amount;
        this.interData['rate'] = fin['rate']
        this.interData['days'] = fin['days']
        this.$Model.addFin(this.interData,data =>{
                  var msg = this.$t('taskOrder2[3]');
                  switch (data.code) {
                    case 1:
                      msg = this.$t('common3[0]');
                      break;
                    case -1:
                      msg = this.$t('hall.kbip[1]');
                      break;
                  }
                  if (data.code==1){
                    this.loadUserinfo();
                    this.loadFindata();
                  }
                  this.$Dialog.Toast(msg);
                }
        );
        this.show_interest = false
      },
      goDown() {
        if (window.plus) {
          this.$router.push("/AppDown");
        } else {
          var appUrl = localStorage["AppUrl"];
          this.$Util.OpenUrl(appUrl);
        }
      },
      //复制成功
      clipboardSuccessHandler({ value, event }) {
        this.$toast({
          message: "Başarıyla kopyala",
        });
        console.log(value);
      },
      copyAddressError(e) {
        this.$toast({
          message: "复制失败",
        });
      },
    },
  };
</script>
<style scoped>
  .PageBox {
    color: #e7e7e7;
    padding: 0;
    background-color: #13171A;
  }

  .vipcls{
    margin-left: 3px;
    padding: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    border:2px solid #0076FA;
  }
  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
    font-weight: bold;
  }

  .header_bg {
    width: 100%;
    top: 0;
    z-index: 0;
    height:300px;
    background-size:contain;
  }

  .MyHeader {
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 72px;
    height: 72px;
  }
  .Mine_box {
    padding: 2px;
    overflow: hidden;
    margin-left: 10px;
    flex-shrink: 0;
    display: flex;
  }
  .MyHeaderStatus {
    width: max-content;
    padding: 0 7px;
    margin-top: 5px;
    margin-left: 10px;
    font-size: 12px;
    border-radius: 20px;
    background: #F3C13A;
    color:#fff;
    line-height: 25px;
    display: flex;
    text-align: center;
  }

  .user_vip {
    width: 44px;
    height: 19px;
    background: url(../../../public/static/icon/user_vip01.png) no-repeat;
    background-size: 100% 100%;
    margin-left: 13px;
    color: #4e51bf;
    font-style: italic;
    text-align: center;
    line-height: 19px;
    padding-right: 5px;
    margin: 5px 0 9px 13px;
    font-weight: bold;
  }

  .MyHeaderStatus .MyHeaderStatus_radius {
    width: 8px;
    height: 8px;
    background: #ff93a4;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 2px;
  }

  .userName {
    width: 220px;
    font-size: 21px;
    color:#fff;
    font-family: emoji;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tool00 {
    width: 95%;
    border-radius: 10px;
    background-color: #191C23;
    overflow: hidden;
    z-index: 99;
    padding: 0 5px;
    align-items: center;
    margin-left: 10px;
    margin-top: 50px;
    padding: 20px;
  }

  .tool01 {
    width: 95%;
    border-radius: 10px;
    background-color: #191C23;
    overflow: hidden;
    z-index: 99;
    color: #aaaaaa;
    padding: 0 5px;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
  }

  .tool02 {
    width: 95%;
    border-radius: 10px;
    background-color: #191C23;
    overflow: hidden;
    z-index: 99;
    color:#fff;
    padding: 0 5px;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
  }

  .tool02 >>> .van-cell {
    padding: 17px 0;
    background-color: #191C23;
  }

  .close img {
    width: 36px;
    margin-top: 20px;
  }

  .UserInfo .info0 {
    margin-top: -255px;
    z-index: 10;
    background: transparent none repeat scroll 0% 0%;
  }

  .UserInfo .info {
    margin-top: -185px;
    z-index: 10;
    /*background-color: #151d31;*/
    /*margin-top: 10px;*/
    color: #fff;
    position: relative;
    z-index: 3;
    background: transparent none repeat scroll 0% 0%;
    position: relative;
    padding: 10px 16px 6px 20px;
  }

  .tool02 >>> .van-cell__title {
    color: #e7e7e7;
  }

  .UserInfo .info .van-cell__title {
    font-size: 12px;
  }

  .UserInfo .info .van-cell__label {
    font-size: 14px;
    background: transparent none repeat scroll 0% 0%;
  }

  .UserInfo .info .van-cell__value {
    flex: none;
    line-height: 1.3;
    background: transparent none repeat scroll 0% 0%;
  }

  .UserInfo .info .van-cell__value a {
    text-align: center;
    /*color: #fff;*/
    display: block;
  }

  .mygrid {
    padding: 5px 10px;
  }

  .mygridvalue {
    color:#fff0cd;
  }
  .top3 >>> .van-grid-item__content {
    padding: 8px 8px 12px !important;
  }
  .top2 {
    padding: 0 20px;
  }
  .top2 >>> .van-cell__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .top2 >>> .van-cell__title > span {
    font-size: 17px;
  }
  .top2 >>> .van-cell__title .van-cell__label {
    padding: 0px 0;
    color:#ABABAB;
    font-size: 12px;
  }
  .top3 >>> .van-grid-item {
    width: 30%;
  }
  .top3 >>> .van-icon img {
    width: 31px;
    height: 31px;
  }
  .top3 >>> .van-grid-item__text {
    font-size: 13px;
    color:#fff;
    font-family: Alibaba;
    font-weight: normal;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }

  .top4 {
    padding: 0 15px;
  }

  .top4 >>> .van-cell {
    display: flex;
    justify-content: center;
    padding: 7px 16px;
  }

  .top4 >>> .van-cell .van-cell__title {
    flex: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* color:#fff; */
  }
  .top4_box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .top4_box >>> .van-cell {
    background: #4e51bf;
    border-radius: 10px;
    padding: 0px 15px;
    margin: 0 auto;
    width: max-content;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
  }

  .top4_box >>> .van-cell__title span {
    color: #fff !important;
    font-size: 13px !important;
  }

  .top4 >>> .van-cell__title span {
    font-size: 13px;
    color:#fff;
    font-family: Alibaba;
    font-weight: normal;
  }

  .top4 >>> .van-grid-item {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
  }

  .top4 >>> .border_none {
    border-bottom: none;
    margin-bottom: 0;
  }

  .top4 >>> .padding_0 .van-cell {
    padding: 10px 0;
  }
  .Menu {
    /*border-top: 10px #0e1526 solid;*/
    margin-top: 5px;
    line-height: 22px;
  }

  .Menu >>> .van-cell {
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #191C23;
    padding: 0px 0px;
  }

  .Menu >>> .van-cell__label {
    font-size: 18px;
    color: #fff;
    text-align: center;
  }


  .Menu >>> .van-grid-item__content {
    background: transparent none repeat scroll 0% 0%;
    padding: 16px 0px;
  }

  .PageBox>>> .van-dialog {
    background-color: #13171A;
    color: #d7d7d7;
  }
  .van-dialog>>>.van-field__control {
    background-color: #191C23;
    color: #fff;
  }

  .van-dialog>>>.van-cell {
    background-color: #191C23;
    color: #fff;
  }

  .PageBox>>> .van-dialog__confirm{
    background-color: #13171A;
    color: #0076FA;
  }
  .PageBox>>> .van-dialog__cancel{
    background-color: #13171A;
    color: #fff;
  }
</style>